import React from "react";
import FAQ from "components/faqs/SingleCol.js";
import Pricing from "components/pricing/Pricing";
import Header from "components/headers/light.js";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Footer from "components/footers/MiniCenteredFooter";
// import Testimonial from "components/testimonials/Testimonial";

export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <Pricing />
      {/* <Testimonial heading="Our Paying Customers" /> */}
      {/* <FAQ /> */}
      <Footer />
    </AnimationRevealPage>
  );
};
