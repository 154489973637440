import B from "images/newImages/server racking stacking.webp";
import C from "images/newImages/server break fix.jpeg.jpg";
import D from "images/newImages/server admin support.webp";

export const DServiceData = [
  {
    id: 2,

    heading: "Server Racking Stacking",
    subheading: "Repairing",
    description:
      "Efficient server racking and stacking is essential for any modern data center or IT infrastructure. Proper server rack setup involves expertly mounting, aligning, and securing servers in a space-efficient way that maximizes cooling, power management, and cable organization. Whether setting up a small network or scaling to an enterprise-level data center, ensuring optimal rack stacking can improve server performance, simplify maintenance, and enhance network reliability. This structured approach to server rack installation helps prevent overheating, reduces downtime, and ensures easy access for hardware upgrades and routine maintenance. Discover the best practices for server racking, rack stack design, and data center rack solutions to keep your systems running smoothly",
    primaryButtonText: "More Info",
    imageSrc: B,
    textOnLeft: false,
  },
  {
    id: 3,
    heading: "Server Break fix",
    subheading: "Fixing",
    description:
      "Our server break-fix services provide fast, reliable support to restore your server systems to peak performance. We specialize in diagnosing and repairing unexpected server hardware failures, network interruptions, and performance issues to minimize downtime. Our break-fix support includes everything from hardware troubleshooting and replacement to network optimization and server repair, ensuring your critical IT infrastructure remains operational. With skilled technicians ready to respond quickly, our server break-fix solutions deliver dependable, on-demand support to maintain data center reliability and extend the longevity of your server systems",
    primaryButtonText: "More Info",
    imageSrc: C,
    textOnLeft: true,
  },
  {
    id: 4,
    heading: "Server admin support",
    subheading: "IT Support",
    description:
      "Our server admin support services offer comprehensive, proactive management to keep your servers secure, efficient, and reliable. From server monitoring and performance optimization to system updates and security management, our experienced team provides end-to-end support for your IT infrastructure. We specialize in troubleshooting server issues, managing user access, and ensuring high network availability to prevent downtime. Our server administration services deliver expert maintenance, rapid response to issues, and tailored solutions that align with your business needs. Trust our skilled server admin support to optimize performance, enhance security, and maintain seamless operations for your organization",
    primaryButtonText: "More Info",
    imageSrc: D,
    textOnLeft: false,
  },
];
