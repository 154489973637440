import WD from "images/services/WD.jpg";
import SD from "images/services/SD.jpg";
import IC from "images/services/IC.jpg";
import EC from "images/services/EC.jpg";
import AM from "images/services/AM.jpg";
import DMS from "images/services/DA.jpg";
import MAD from "images/services/MAD.jpg";
import MIS from "images/services/MIS.jpg";
import SEO from "images/services/SEO.jpg";
import SMM from "images/services/SMM.jpg";
import RII from "images/services/RII.jpg";
import SR from "images/services/SR.jpg";
import PM from "images/services/PM.jpg";
import A from "images/newImages/web development course.webp";

export const serviceData = [
  {
    id: 0,
    heading: "Repair laptop, Printer and Networking",
    subheading:
      "Expert Laptop Repair, Printer Servicing, and Network Issue Resolution for Reliable IT Solutions",
    description:
      "As a dedicated IT professional, Laptop repair, printer troubleshooting and maintenance, and network issue resolution. Hands-on experience, I provide quick, effective repairs to keep your devices running smoothly, ensuring seamless connectivity and optimal performance. From fixing hardware and software issues on laptops, servicing and repairing printers, to diagnosing and resolving network problems, I offer comprehensive solutions designed to maximize uptime and productivity. Trust in expert service for fast, dependable IT support for all your laptop, printer, and network needs!",
    primaryButtonText: "More Info",
    imageSrc: A,
    textOnLeft: true,
  },
  {
    id: 1,
    heading: "Rapid Issue Identification",
    subheading: "Data Center Troubleshoot",
    description:
      "Our experienced technicians are skilled in identifying the root causes of data center problems, whether they involve hardware, software, connectivity, or environmental factors",
    primaryButtonText: "More Info",
    imageSrc: RII,
    textOnLeft: false,
  },
  {
    id: 2,

    heading: "Swift Resolutions",
    subheading: "Data Center Troubleshoot",
    description:
      "We understand the critical nature of data center disruptions. Our team is dedicated to delivering rapid solutions, minimizing impact, and restoring your operations promptly",
    primaryButtonText: "More Info",
    imageSrc: SR,
    textOnLeft: true,
  },
  {
    id: 3,

    heading: "Proactive Maintenance",
    subheading: "Data Center Troubleshoot",
    description:
      "Beyond troubleshooting, we provide insights to help prevent future issues. Our proactive maintenance recommendations are designed to enhance the stability and reliability of your data center infrastructure.",
    primaryButtonText: "More Info",
    imageSrc: PM,
    textOnLeft: false,
  },
  {
    id: 4,

    heading: "Website Development",
    subheading: "IT Service",
    description:
      "Craft responsive and intuitive websites that align with your brand, ensuring seamless user experiences and effective communication of your message",
    primaryButtonText: "More Info",
    imageSrc: WD,
    textOnLeft: true,
  },
  {
    id: 5,

    heading: "Mobile App Development",
    subheading: "IT Service",
    description:
      "Create user-friendly mobile apps that cater to your audience's needs, providing convenient access to your products or services",
    primaryButtonText: "More Info",
    imageSrc: MAD,
    textOnLeft: false,
  },
  {
    id: 6,

    heading: "Software Development",
    subheading: "IT Service",
    description:
      "Tailor software solutions to optimize your operations, leveraging technology to enhance efficiency and meet specific business goal",
    primaryButtonText: "More Info",
    imageSrc: SD,
    textOnLeft: true,
  },
  {
    id: 7,

    heading: "IT Consulting",
    subheading: "IT Service",
    description:
      "Benefit from expert guidance to formulate IT strategies that enhance productivity, security, and align technology with your business objectives",
    primaryButtonText: "More Info",
    imageSrc: IC,
    textOnLeft: false,
  },
  {
    id: 8,

    heading: "E-commerce Solutions",
    subheading: "IT Service",
    description:
      "Establish and manage online stores with secure payment gateways, inventory management, and user-friendly interfaces for optimal customer experiences.",
    primaryButtonText: "More Info",
    imageSrc: EC,
    textOnLeft: true,
  },
  {
    id: 9,

    heading: "Managed IT Services",
    subheading: "IT Service",
    description:
      "Delegate IT management to experts, gaining proactive support, maintenance, and troubleshooting to keep your systems running seamlessly",
    primaryButtonText: "More Info",
    imageSrc: MIS,
    textOnLeft: false,
  },
  {
    id: 10,

    heading: "Search Engine Optimization (SEO)",
    subheading: "Digital Marketing Service",
    description:
      "Enhance online visibility and drive organic traffic through strategic search engine optimization techniques tailored to your target audience.",
    primaryButtonText: "More Info",
    imageSrc: SEO,
    textOnLeft: true,
  },
  {
    id: 11,

    heading: "Social Media Marketing",
    subheading: "Digital Marketing Service",
    description:
      "Amplify brand presence on social platforms, engaging users and building strong connections through well-crafted content and interactions.",
    primaryButtonText: "More Info",
    imageSrc: SMM,
    textOnLeft: false,
  },
  {
    id: 12,

    heading: "Affiliate Marketing",
    subheading: "Digital Marketing Service",
    description:
      "Collaborate with affiliates to promote your offerings and gain wider exposure.",
    primaryButtonText: "More Info",
    imageSrc: AM,
    textOnLeft: true,
  },
  {
    id: 13,

    heading: "Analytics and Data Analysis",
    subheading: "Digital Marketing Service",
    description: "Monitoring and analyzing data to optimize marketing efforts.",
    primaryButtonText: "More Info",
    imageSrc: DMS,
    textOnLeft: false,
  },
];
